<template>
    <div class="p-5 mx-0 md:mx-16 lg:mx-32">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Page'
};
</script>

